.App {

}

.App-header {
}

.App-link {
  color: #61dafb;
}

#root {
  font-size: calc(10px + 2vmin);
  /* background: rgb(23, 22, 25); */
  min-height: 100vh;
}

.Toastify__toast-body {
  font-family: 'GT Flexa Trial';
  font-size: 16px;
  white-space: pre-wrap;
}

/* SHOW INPUT ARROWS */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 0;
   display: none;
}

/*
FONTS 
*/
@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url(./fonts/MonumentExtended-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Monument Extended';
  font-weight: bold;
  src: local('Monument Extended'), url(./fonts/MonumentExtended-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Monument Extended';
  font-weight: 300;
  src: local('Monument Extended'), url(./fonts/MonumentExtended-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono';
  src: local('Roboto Mono'), url(./fonts/RobotoMono-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'GT Flexa Trial';
  src: local('GT Flexa Trial'), url(./fonts/GT-Flexa-VF-Trial.ttf) format('truetype');
}

@font-face {
  font-family: 'GT Flexa Expanded';
  src: url(./fonts/gt-flexa/GT-Flexa-Expanded-Medium-Trial.woff2);
}

@font-face {
  font-family: 'GT Flexa Lazer';
  src: url(./fonts/gt-flexa/GT-Flexa-Lazer-Trial.woff2);
}

/*
KEYFRAME ANIMATIONS
*/

/* Shine Animations*/
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.8s, 0.8s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@-moz-keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.8s, 0.8s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@-webkit-keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.8s, 0.8s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

/* Bounce Animation*/
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-5px);} 
} 
@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-5px);} 
}
@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
  40% {transform: translateY(-10px);} 
  60% {transform: translateY(-5px);} 
}

/* Image Cascade Animation*/
@keyframes clip1{
  0%{clip-path: polygon(0% -50%, 50% -50%, -50% 50%, -100% 50% );}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clipa{
  0%{clip-path: polygon(-5% -55%, 55% -45%, -45% 55%, -95% 55% );}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clip2{
  0%{clip-path: polygon(50% -50%, 100% -50%, 200% 50%, 150% 50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}
@keyframes clipb{
  0%{clip-path: polygon(40% -60%, 100% -50%, 190% 40%, 150% 50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}
@keyframes clip3{
  0%{clip-path: polygon(-100% -150%, -50% -150%, -150% -50%, -200% -50%);}
  100%{clip-path: polygon(150% 50%, 200% 50%, 100% 150%, 50% 150%);}
}
@keyframes clip4{
  0%{clip-path: polygon(150% -150%, 200% -150%, 300% -50%, 250% -50%);}
  100%{clip-path: polygon(-100% 50%, -50% 50%, 50% 150%, 0% 150%);}
}


.shiny-thumbnail:after {
  animation: shine 5s ease-in-out infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.clamp2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

h1, h2, h3 {
  /* font-family: Monument Extended; */
  font-family: GT Flexa Trial;
}

body {
  /* font-family: Roboto Mono; */
  font-family: GT Flexa Trial;
}

.outlined {
  color: white;
	-webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: black;
}

.outlined-white {
  color: transparent;
	-webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: white;
}

.bold {
  font-weight: bold;
}


.ReactModal__Body--open {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ReactModal__Overlay {
  z-index: 4;
}


/* test */

.spin {
  cursor: pointer;
  transform-style: preserve-3d;
}
.spin:hover {
    animation: spin 5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.cube {
  position: relative;
  width: 95px;
}
.side {
  height: 95px;
  line-height: 95px;
  position: absolute;
  text-align: center;
  width: 95px;
  border: 3px solid #000;
  box-sizing: border-box;
}
.top {
  transform: rotate(-45deg) skew(15deg, 15deg);
}
.left {
  transform: rotate(15deg) skew(15deg, 15deg) translate(-50%, 100%);
}
.right {
  transform: rotate(-15deg) skew(-15deg, -15deg) translate(50%, 100%);
}

.clip{
  filter:grayscale(1);
  position: fixed;
  width:100%;
  height:100%;
  transform-origin:center;
}
#clip1 { animation:clip1 4s linear infinite; }
#clipa {
  transform: scale(1.1,1.1);
  animation:clipa 9s linear infinite;  
}
#clip2 {
  transform: scale(1.12,1.12); 
  animation:clip2 5s linear infinite;  
}
#clipb {
  transform: scale(1.14,1.14);
  animation:clipb 4s linear infinite;  
}
#clip3 {
  transform: scale(1.15,1.15);
  animation:clip3 7s linear infinite;  
}
#clip4 {
  transform: scale(1.13,1.13);
  animation:clip4 6s linear infinite;  
}